import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.section`
  background-color: #ffeb98;
  color: #ec626b;
  margin: 0 auto;
  text-align: center;
  p {
    color: #000000;
  }
`

const SuccessPage = () => (
  <Layout>
    <SEO
      title="Ordine confermato!"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Container>
      Grazie per averci scritto! Ti risponderemo appena possibile!
    </Container>
  </Layout>
)

export default SuccessPage
